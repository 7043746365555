import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

interface Props {
  voditelj: {
    name: string;
    email: string;
    phone: string;
    image: { asset: { gatsbyImageData: IGatsbyImageData } };
  };
}

const ContactCard: React.FC<Props> = ({ voditelj: { name, email, phone, image } }) => (
  <Card>
    <ImageContainer>
      <GatsbyImage image={image.asset.gatsbyImageData} alt={name} />
    </ImageContainer>
    <TextContainer>
      <Name>{name}</Name>
      <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
      <ContactLink href={`tel:${phone}`}>
        {phone.slice(0, 3)}/{phone.slice(3, 6)}-{phone.slice(6, 9)}
      </ContactLink>
    </TextContainer>
  </Card>
);

const Card = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
  padding: 2rem 5rem 3.4rem 5rem;

  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);

  display: grid;
  grid-template-rows: 250px auto;
  gap: 3rem;

  @media (max-width: 1400px) {
    gap: 2rem;
    padding: 2rem 3rem 3.4rem 3rem;
  }
  @media (max-width: 800px) {
    gap: 1rem;
    padding: 1rem;
    grid-template-rows: 200px auto;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    clip-path: circle(50% at 50% 50%);
  }

  @media (max-width: 800px) {
    transform: scale(0.75);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Name = styled.p`
  cursor: default;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 3.4rem;
  opacity: 0.9;
`;

const ContactLink = styled.a`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.8rem;

  position: relative;
  justify-self: start;

  &:after {
    transition: width 0.3s ease-out;
    content: '';
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 0%;
    height: 2px;
    background: var(--modra);
    opacity: 0.6;
    z-index: -1;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--modra);
  }
`;

export default ContactCard;
