import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import ContactCard from '../components/ContactCard';
import SEO from '../components/SEO';

export const query = graphql`
  {
    voditelji: allSanityVoditelji {
      nodes {
        id
        name
        email
        phone
        image {
          asset {
            gatsbyImageData(layout: FIXED, height: 200, width: 200)
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    voditelji: {
      nodes: {
        id: string;
        name: string;
        email: string;
        phone: string;
        image: { asset: { gatsbyImageData: IGatsbyImageData } };
      }[];
    };
  };
}

const kontakt: React.FC<Props> = ({ data }) => (
  <>
    <SEO title="Kontakt" />
    <Container>
      {data.voditelji.nodes.map(voditelj => (
        <ContactCard voditelj={voditelj} key={voditelj.id} />
      ))}
    </Container>
  </>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  place-items: start stretch;
  gap: 4rem;

  @media (max-width: 1400px) {
    gap: 2rem;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    place-items: center;
  }

  margin: 2rem 0 6rem 0;
`;

export default kontakt;
